<template>
  <div id="user-profile">
    <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
      <navbar />
    </b-navbar>
    <b-container class="mb-3">
      <br />
      <br />
      <h3 class="text-primary text-center">Ranking SNBP</h3>
      <b-card style="margin-left: 35%; margin-right: 35%" class="bg-primary mt-3">
        <b-card-body class="">
          <b-row>
            <b-col md="5" class="text-white">
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon size="sm" icon="BarChart2Icon" />
                </template>
              </b-media>
            </b-col>
            <b-col md="7" class="text-white">
              <h4 class="media-heading mb-2 text-white">
                <b> Rank Saya</b>
              </h4>
              <span v-if="myRanking.ranking[0] != null">
                <b-row>
                  <b-col v-for="(item, i) in myRanking.ranking" :key="i">
                    <h6 class="text-white">{{ item.subcat_name }}</h6>
                    <b>
                      {{ item.ranking.ke }}
                    </b>
                    /{{ item.ranking.dari }}
                  </b-col>
                </b-row>
              </span>
              <span v-else>
                <b> {{ myRanking.ranking.ke ? myRanking.ranking.ke : myRanking.ranking }}</b
                >/{{ totalRows }}
              </span>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-overlay :show="loading">
        <b-card>
          <h4 class="text-center">Top 10 Rank</h4>

          <b-row class="mt-4">
            <b-col md="4" sm="4" class="">
              <label v-if="paketRanking[0].majors">Filter Jurusan</label>
              <v-select class="select-size-sm w-full" label="text" v-model="filterJurusan" @input="getRanking()" :clearable="true" :options="optJurusan" />
            </b-col>
            <b-col md="2" sm="2" class=""> </b-col>
            <b-col md="3" sm="3" class="">
              <b-form-group label="Filter Berdasarkan Sekolah Anda?" label-for="filter">
                <b-form-group>
                  <b-form-checkbox v-model="filterSekolah" @input="getRanking()" switch inline />
                </b-form-group>
              </b-form-group>
            </b-col>
            <b-col md="3" sm="3" class="">
              <b-form-group label="Filter Berdasarkan Provinsi Anda?" label-for="filter">
                <b-form-group>
                  <b-form-checkbox v-model="filterProvinsi" @input="getRanking()" switch inline />
                </b-form-group>
              </b-form-group>
            </b-col>
            <b-col md="12" class="my-1">
              <table class="table table-bordered responsive">
                <tr class="bg-primary text-white" variant="primary">
                  <th>Peringkat</th>
                  <th>Nama</th>
                  <th>Target Jurusan 1</th>
                  <th>Target Jurusan 2</th>
                  <th>Skor</th>
                </tr>
                <tr v-for="(item, i) in paketRanking" :key="i">
                  <td>{{ item.ranking.ke ? item.ranking.ke : item.ranking }}</td>
                  <td>{{ item.user_name ? item.user_name : item.name }}</td>
                  <td>{{ item.majors && item.majors[0] ? item.majors[0].major_name : "-" }}</td>
                  <td>{{ item.majors && item.majors[1] ? item.majors[1].major_name : "-" }}</td>
                  <td>{{ item.jumlah_nilai }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
        <br />
        <br />
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
import {
  BOverlay,
  BMedia,
  BRow,
  BCol,
  BContainer,
  BCard,
  BCardBody,
  BCardFooter,
  BBadge,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";

import Navbar from "./components/Navbar.vue";
import vSelect from "vue-select";
/* eslint-disable global-require */
export default {
  components: {
    vSelect,
    BOverlay,
    BMedia,
    BRow,
    BCol,
    BContainer,
    BCard,
    BCardBody,
    BCardFooter,
    BBadge,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    Navbar,
  },

  data() {
    return {
      filterJurusan: null,
      filterSekolah: false,
      filterProvinsi: false,
      paketRanking: null,
      optJurusan: null,
      myRanking: null,
      loading: false,
      totalRows: 0,
    };
  },

  computed: {
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
  },

  methods: {
    async getRanking() {
      this.loading = true;
      const payload = {
        paket_id: this.paketDetail.id,
        school_origin: this.filterSekolah != false ? this.user.detail.school_origin : null,
        provinsi: this.filterProvinsi != false && this.user.detail.school_origin_info ? this.user.detail.school_origin_info.provinsi : null,
        major_id: this.filterJurusan != null ? this.filterJurusan.value : null,
      };
      this.$store
        .dispatch("ujian/indexRanking", payload)
        .then((response) => {
          this.loading = false;
          let paketRanking = response.data.data;
          this.paketRanking = paketRanking;
          if (this.myRanking == null) {
            let myRanking = paketRanking.find((res) => {
              return res.user_id == this.user.id;
            });
            this.myRanking = myRanking;
            this.totalRows = this.paketRanking.length;
          }
          if (this.optJurusan == null) {
            let optJurusan = [];
            this.paketRanking.forEach((el) => {
              el.majors.forEach((major) => {
                const existMajor = optJurusan.find((jurusan) => jurusan.value == major.major_id);
                if (!existMajor) {
                  optJurusan.push({
                    value: major ? major.major_id : "-",
                    text: major ? major.major_name : "-",
                  });
                }
              });
            });
            this.optJurusan = optJurusan;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },

  created() {
    this.getRanking();
  },
};
</script>
